$(function () {
  $.get('img/icons.svg', function (data) {
    var icons = document.createElement('div');
    icons.className = 'icons';
    icons.innerHTML = new XMLSerializer().serializeToString(data.documentElement);
    document.body.insertBefore(icons, document.body.childNodes[0]);
  });

  var clipboard = new Clipboard('.ctc');

  clipboard.on('success', function (e) {
    e.clearSelection();
    $.snackbar({
      message: 'In Zwischenablage kopiert.'
    });
  }).on('error', function (e) {
    $.snackbar({
      message: 'Betätige &#8984;+C zum kopieren.'
    });
  });
});



// NOTE: window.RTCPeerConnection is "not a constructor" in FF22/23
var RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

if (RTCPeerConnection)(function () {
  if (!$('#tpl-app-item').length) return;
  var rtc = new RTCPeerConnection({
    iceServers: []
  });
  if (1 || window.mozRTCPeerConnection) { // FF [and now Chrome!] needs a channel/stream to proceed
    rtc.createDataChannel('', {
      reliable: false
    });
  };

  rtc.onicecandidate = function (evt) {
    // convert the candidate to SDP so we can run it through our general parser
    // see https://twitter.com/lancestout/status/525796175425720320 for details
    if (evt.candidate) grepSDP("a=" + evt.candidate.candidate);
  };
  rtc.createOffer(function (offerDesc) {
    grepSDP(offerDesc.sdp);
    rtc.setLocalDescription(offerDesc);
  }, function (e) {
    console.warn("offer failed", e);
  });


  var addrs = Object.create(null);
  addrs["0.0.0.0"] = false;

  function updateDisplay(newAddr) {
    if (newAddr in addrs) return;
    else addrs[newAddr] = true;
    var displayAddrs = Object.keys(addrs).filter(function (k) {
      return addrs[k];
    });

    var tpl = $('#tpl-app-item').html().trim(),
      $appItem,
      $appItemList = $('.item-group').find('.item'),
      $appItemBefore = $appItemList.last();

    $appItemList.not(':last').remove();

    $.each(displayAddrs, function (key, value) {
      var iterator = key + 1;
      $appItem = $(tpl);

      $appItem.find('.item-control').val(value);
      $appItem.find('a.item-btn').attr('href', '//' + value).attr('aria-label', function (i, val) {
        return val + value;
      });

      if (key) {
        $appItem.addClass('item-secondary');

        $appItem.find('.item-label').attr('for', function (i, val) {
          return val + '-' + iterator;
        });

        $appItem.find('.item-label').find('span').html(function (i, val) {
          return val + ' ' + iterator;
        });

        $appItem.find('.item-control').attr('id', function (i, val) {
          return val + '-' + iterator;
        });

        $appItem.find('button.item-btn').attr('data-clipboard-target', function (i, val) {
          return val + '-' + iterator;
        });
      }

      $appItemBefore.before($appItem);
    });
  }

  function grepSDP(sdp) {
    var hosts = [];
    sdp.split('\r\n').forEach(function (line) { // c.f. http://tools.ietf.org/html/rfc4566#page-39
      if (~line.indexOf("a=candidate")) { // http://tools.ietf.org/html/rfc4566#section-5.13
        var parts = line.split(' '), // http://tools.ietf.org/html/rfc5245#section-15.1
          addr = parts[4],
          type = parts[7];
        if (type === 'host') updateDisplay(addr);
      } else if (~line.indexOf("c=")) { // http://tools.ietf.org/html/rfc4566#section-5.7
        var parts = line.split(' '),
          addr = parts[2];
        updateDisplay(addr);
      }
    });
  }
})();
else {
  $.snackbar({
    message: 'Benutze den <a href="//google.com/chrome/">Chrome</a> oder <a href="//mozilla.org/firefox/">Firefox</a> Browser um deine lokale IP sehen zu können.',
    button: 'Ok',
    duration: {
      prevent: true
    }
  });
  //  document.getElementById('list').innerHTML = "<code>ifconfig | grep inet | grep -v inet6 | cut -d\" \" -f2 | tail -n1</code>";
  // document.getElementById('list').nextSibling.textContent = "In Chrome and Firefox your IP should display automatically, by the power of WebRTCskull.";
}
