(function ($, window, document, undefined) {
  var Snackbar = {
    init: function (options) {
      var self = this;
      self.name = 'snackbar';
      self.booting = true;

      if (typeof options === 'string') {
        options = {
          message: options
        };
      }
      self.options = $.extend(true, {}, $.snackbar.options, options);

      self.$html = $('html');
      self.$wrap = $('.' + self.name);
      self.$body = null;
      self.$action = null;
      self.$button = null;

      self.clear(function () {
        self.build();
        self.events();
        self.duration();
        self.collapse('show', function () {
          self.booting = false;
        });
      });

    },
    events: function () {
      var self = this;

      if (self.$button) {
        self.$button.one('click', function () {
          if (typeof self.options.onClick === 'function') {
            self.options.onClick();
          }
          self.clear();
        });
      }
    },
    clear: function (callback) {
      var self = this;

      clearTimeout(window[self.name + '-timeout']);

      self.collapse('hide', function () {
        self.$wrap.empty().removeData().unbind();
        if (typeof callback === 'function') {
          callback();
        }
      });
    },
    collapse: function (mode, callback) {
      var self = this;

      self.$wrap.addClass('collapsing');

      if (mode === 'hide') {
        if (typeof self.options.onHide === 'function' && !self.booting) {
          self.options.onHide();
        }
        self.$html.removeClass('has-' + self.name);
        self.$wrap.slideUp(self.options.duration.out * 1000, function () {
          if (typeof self.options.onHidden === 'function' && !self.booting) {
            self.options.onHidden();
          }
        });
      } else if (mode === 'show') {
        if (typeof self.options.onShow === 'function') {
          self.options.onShow();
        }
        self.$html.addClass('has-' + self.name);
        self.$wrap.slideDown(self.options.duration.in * 1000, function () {
          if (typeof self.options.onShown === 'function') {
            self.options.onShown();
          }
        });
      }

      self.$wrap.promise().done(function () {
        self.$wrap.removeClass('collapsing').toggleClass('in', self.$wrap.is(':visible'));
        if (typeof callback === 'function') {
          callback();
        }
      });
    },
    duration: function () {
      var self = this;

      if (self.options.duration.prevent && self.$button && self.$wrap) return;

      window[self.name + '-timeout'] = setTimeout(function () {
        self.clear();
      }, (self.options.duration.in * 1000) + (self.options.duration.display * 1000));

    },
    build: function () {
      var self = this;

      self.$wrap = $('<div>', {
        class: self.name
      });
      $(self.options.container).append(self.$wrap);

      if (self.options.message) {
        self.$body = $('<div>', {
          class: self.name + '-message',
          html: self.options.message
        });
        self.$wrap.append(self.$body);
      }

      if (self.options.button) {
        self.$action = $('<div>', {
          class: self.name + '-action'
        });
        self.$button = $('<button>', {
          class: 'btn btn-' + self.name,
          type: 'button',
          html: self.options.button
        });
        self.$wrap.append(
          self.$action.append(
            self.$button
          )
        );
      }
    },
    destroy: function () {
      var self = this;

      self.clear(function () {
        self.$wrap.unbind().remove();
      });
    }
  };

  $.snackbar = function (options) {
    var snackbar = Object.create(Snackbar);
    snackbar.init(options);
  };

  $.snackbar.destroy = function () {
    var snackbar = Object.create(Snackbar);
    snackbar.init();
    snackbar.destroy();
  };

  $.snackbar.options = {
    container: 'body',
    message: null,
    button: null,
    duration: {
      display: 3,
      in : .25,
      out: .25,
      prevent: false
    },
    onClick: null,
    onHide: null,
    onHidden: null,
    onShow: null,
    onShown: null
  };
})(jQuery, window, document);